import React from 'react'
import {Button} from "antd";

export default {
    name: 'kpt2023',
    title: 'KomPlasTech 2023',
    subTitle: 'XXVIII Conference on Computer Methods in Materials Technology',
    conferenceSiteUrl: 'http://komplastech.agh.edu.pl/',
    registrationFormText: (<span>Complete the form below in order to register for the KomPlasTech 2023 Conference in Zakopane,
        March 5-8, 2023. If you experience any problems with the site, you may wish to try another browser.
        If you continue to have problems, please contact us at <a
            href="mailto:barana@agh.edu.pl">barana@agh.edu.pl</a>.</span>),
    feeTabVisible: true,
    paperTabVisible: false,
    backgroundImage: '/background_komplastech2023.webp',
    abstractTemplate: 'http://komplastech.agh.edu.pl/public_repo/abstract.docx',
    paperTemplate: null,
    copyrightsForm: null,
    sentryDsn: 'https://179bca37dc92475089165779b4313ff6@sentry.kisim.eu.org/3',
    feeText: (<div style={{textAlign: 'center'}}>
            <p>
                <p>
                    The registration fee for delegates and authors is 2500PLN (1700PLN for students) if paid before January 31, 2023 and 2800PLN (2000PLN for students) if paid after that date.
                </p>
                <p>
                    For <a href="https://www.eccomas.org/">ECCOMAS</a> members 10% discount.
                </p>
                <p>
                    The fee includes Conference Proceedings, reception, lunches, coffee breaks, banquet and social program.<br/>
                    PAYMENT METHODS - only by bank transfer in PLN (polish zloty) to the following account:
                    </p>
                <p style={{fontWeight: "bold"}}>
                    Bank: PEKAO S.A.<br/>
                    Bank account holder:  Fundacja Studentów i Absolwentów AGH w Krakowie "Academica"<br/>
                    ul. Rostafińskiego 10<br/>
                    30-072 Kraków, POLAND<br/>
                    IBAN code: PL 11 1240 4533 1111 0010 2978 5411<br/>
                    SWIFT code: PKOPPLPW<br/>
                    Bank transfer title: KomPlasTech 2023 - name of participant<br/><br/>
                    Please note: Any costs of bank transfer needs to be covered by the sender.
                </p>
            </p>
        </div>
    ),
    menu: {
        papersList: 'List of full papers',
        submitPaper: 'Submit full paper'
    },
    fees: [
        {
            id: 'FULL_EARLY',
            label: 'Full early'
        },
        {
            id: 'FULL_LATE',
            label: 'Full late'
        },
        {
            id: 'STUDENT_EARLY',
            label: 'Student early'
        },
        {
            id: 'STUDENT_LATE',
            label: 'Student late'
        },
        {
            id: 'STAFF',
            label: 'Technical staff'
        },
        {
            id: 'INVITED',
            label: 'Invited'
        },
        {
            id: 'CASH',
            label: 'Cash on desk'
        }
    ],
    apiUrl: process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:9000/api',
}
