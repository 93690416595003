import React, {useState} from 'react'
import {Select} from "antd";

const KeywordsSelectInternal = ({onSearch, keywords, onChange, value = [], ...rest}) => {
    const [open, setOpen] = useState(false);

    const handleSelect = (value) => {
    }

    const handleChange = (value) => {
        setOpen(false);
        onChange(value);
    }


    const handleSearch = (value) => {
        setOpen(true);
        value = value.trim();        // prevents spaces before
        if (value.length <= 2) {
            return
        }
        onSearch(value);
    }

    return (
        <Select
            mode="tags"
            tokenSeparators={[',',';']}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onSelect={handleSelect}
            notFoundContent={null}
            onChange={ handleChange }
            open={open}
            value={value}
            {...rest}
        >
            {keywords.map((keyword, i) => <Select.Option key={`${keyword}_${i}`}
                                                         value={keyword}>{keyword}</Select.Option>)}
        </Select>
    )
}

export const KeywordsSelect = React.forwardRef( (props, ref) => <KeywordsSelectInternal {...props}  />);
