import React from 'react';
import {Col, Form, Input, Row, Select} from "antd";
import {getData} from "country-list";
import PropTypes from 'prop-types'

const countriesOptionsList = getData().map(c => <Select.Option
    key={c['code']}
    value={c['code']}>
    {c['name']}
</Select.Option>);

const AddressForm = ({form, colOffset}) => {
    return (
        <Row style={{marginTop: 20}}>
            <Col offset={colOffset} xs={24 - 2 * colOffset}>
                <Form form={form} layout="vertical">
                    <Row>
                        <Col xs={14}>
                            <Form.Item name='address' label='Address' extra={"Invoice address including VAT number"}>
                                    <Input.TextArea rows={6} data-cy='address' />
                            </Form.Item>
                        </Col>


                        <Col xs={9} offset={1}>
                            <Form.Item name='country' label='Country'>
                                    <Select
                                        style={{width: "100%"}}
                                        showSearch
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        placeholder="Please select a country"
                                        data-cy='country'

                                    >
                                        {countriesOptionsList}
                                    </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

AddressForm.propTypes = {
    colOffset: PropTypes.number,
};

AddressForm.defaultProps = {
    colOffset: 2
}


export default AddressForm;
